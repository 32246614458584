import React from 'react'
import Layout from '../../components/Layout'

const MembersPage = () => {
    return (
        <Layout>
            <section className="section">
                <div className="container">
                    <div className="content">
                        <h1>Members</h1>
                        <p>You need to be signed in to access the premium version of the simulator</p>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default MembersPage
